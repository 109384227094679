import React, { useState } from 'react'
import { H2 } from '@clubspark-react/clubspark-react-tools'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Breadcrumbs from '../breadcrumbs/breadcrumbs'
import { useMutation } from '@apollo/client'
import { CREATE_SUSPENSION } from '../players/players-queries'
import { meshGatewayClient } from 'src/apollo/client'
import Spinner from '../spinner/spinner'
import { NavigationState } from 'src/utils/typedefs/navigation'
import SuspensionForm from '../suspension-form/suspension-form'
import moment from 'moment'
import { usePlayerByExternalId } from 'src/hooks/data/suspensions'

interface Props {
  id: string
}

const initialValues = {
  startDate: new Date(),
  endDate: moment()
    .add(3, 'months')
    .toDate(),
  comment: '',
  violations: []
}

const SuspendPlayer: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const { fullName, loading: loadingPlayer } = usePlayerByExternalId(id)

  const [createSuspension, { loading, error, data }] = useMutation(CREATE_SUSPENSION, {
    client: meshGatewayClient
  })

  const [selectedViolations, setSelectedViolations] = useState<any>([])

  const editSelectedPoints = (id, checked) => {
    let selected = selectedViolations
    if (checked === true) {
      selected.push(id)
    } else {
      selected = selected.filter(i => i !== id)
    }
    setSelectedViolations(selected)
  }

  const handleSubmit = async values => {
    const input = Object.assign(values, { uaid: id, violations: selectedViolations })
    try {
      const res = await createSuspension({
        variables: {
          input: input
        }
      })
      const state: NavigationState = {
        popup: {
          message: t('player suspended shorthand')
        }
      }
      navigate(`/players/${id}?tab=suspensions`, { state })
    } catch (error) {}
  }

  return loadingPlayer ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${id}` },
          { name: t('add suspension'), active: true }
        ]}
      />
      <H2 spacing={{ margins: { sm: 'top' } }}>
        {t('suspend')} {fullName}
      </H2>
      <SuspensionForm
        id={id}
        formVals={initialValues}
        loading={loading}
        submitError={error}
        handleSubmit={handleSubmit}
        editSelectedPoints={editSelectedPoints}
      />
    </>
  )
}

export default SuspendPlayer
