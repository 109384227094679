import React from 'react'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import { usePrivateRoute } from 'src/utils/auth'
import SuspendPlayer from 'src/components/suspend-player/suspend-player'
import { Router, RouteComponentProps } from '@reach/router'

const PlayerProfileSuspendPlayerPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players/:id">
      <PlayerProfileSuspendPlayer path="/suspend-player" />
    </Router>
  )
}

interface PlayerProfileSuspendPlayerRouteProps extends RouteComponentProps {
  id?: string
}

const PlayerProfileSuspendPlayer: React.FC<PlayerProfileSuspendPlayerRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Suspend player" />
      <SuspendPlayer id={id} />
    </Layout>
  )
}

export default PlayerProfileSuspendPlayerPage
